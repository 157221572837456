import React, { useState, useEffect, useCallback, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getAveragePrice, getSalt, searchProducts } from "../../api/Api";
import Header from "../Dashboard/Header";
import { Footer } from "../LandingPage/LandingPage";
import Navigation from "../Dashboard/Navigation";
import Swal from "sweetalert2";
import axios from "axios";
import DOMPurify from "dompurify";

export const SaltPage = () => {
  const [salt, setSalt] = useState("");
  const [alternateProducts, setAlternateProducts] = useState([]);

  const [tabs, setTabs] = useState({});
  const [activeTab, setActiveTab] = useState("");
  const [description, setDescription] = useState("");
  const [isCollapsed, setIsCollapsed] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const salt_name = decodeURIComponent(location.pathname.replace("/salt_name/", ""));
  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleView = (product) => {
    navigate(`/product_name/${product.product_name_url}`);
  };

  const fetchAlternateProducts = async () => {
    try {
      const query = `salt_name='${salt_name}' AND rc=1`;
      let result = await searchProducts("", 1, { query });

      const response = await getAveragePrice("", salt_name).then((response) => {
        setSalt((prevProduct) => ({
          ...prevProduct,
          averagePrice:
            Math.round(parseFloat(response.averagePrice) * 100) / 100,
        }));
        return response;
      });

      if (result && result.results) {
        const updatedProducts = result.results.map((prod) => ({
          product_name_url: prod.product_name_url,
          originalPrice: prod.product_pricing_old,
          discountedPrice: prod.product_pricing_new,
          discount: Math.round(
            ((parseFloat(response.averagePrice) -
              parseFloat(prod.product_pricing_new)) /
              parseFloat(response.averagePrice)) *
              100
          ),
          imageUrl: prod.first_image_url,
          name: prod.product_name,
          manufacturer: prod.manufacturer,
          product_id: prod.product_id,
        }));
        setAlternateProducts(updatedProducts);
      }
    } catch (error) {
      console.error("Error fetching alternate products:", error);
    }
  };
  const parseDescriptionToTabs = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
  
    // Define header levels to check in order of priority
    const headerLevels = ["h1", "h2", "h3", "h4", "h5", "h6"];
    let sections = null;
  
    // Find the first header level that exists
    for (const level of headerLevels) {
      sections = doc.querySelectorAll(level);
      if (sections.length > 0) {
        break;
      }
    }
  
    // If no headers are found, return an empty object
    if (!sections || sections.length === 0) {
      return {};
    }
  
    const tabsData = {};
  
    sections.forEach((section) => {
      const heading = section.querySelector("strong")?.textContent.trim() || section.textContent.trim();
      const content = [];
  
      let sibling = section.nextElementSibling;
      // Gather all sibling elements until the next section
      while (sibling && !headerLevels.includes(sibling.tagName.toLowerCase())) {
        content.push(sibling.outerHTML);
        sibling = sibling.nextElementSibling;
      }
  
      if (heading) {
        tabsData[heading] = content.join("");
      }
    });
  
    return tabsData;
  };
  
  const fetchSalt = async () => {
    try {
      const response = await getSalt(salt_name);
      console.log("Salt response", response);
      if (response) {
        setSalt({ ...salt, response });

        // Load description
        const desc = await axios.get(
          `https://medingen.s3.us-east-1.amazonaws.com/product_description/${response.description_url}`
        );
        const sanitizedDescription = DOMPurify.sanitize(desc.data);
        setDescription(sanitizedDescription);

        const parsedTabs = parseDescriptionToTabs(sanitizedDescription);
        console.log("Parsed tabs:", parsedTabs);
        setTabs(parsedTabs);
        setActiveTab(Object.keys(parsedTabs)[0]); // Set the first tab as active by default
      } else {
        Swal.fire({
          icon: "error",
          title: "Salt not found",
          text: "Redirecting to home page",
          showConfirmButton: true,
        }).then(() => {
          window.location.href = "/";
        });
      }
    } catch (error) {
      console.error("Error fetching salt:", error);
    }
  };

  useEffect(() => {
    if (salt_name) {
      fetchSalt();
      fetchAlternateProducts();
    }
  }, [salt_name]);

  return (
    <>
      <Header title={salt_name} />
      <div className="search-view-medicine">
        <div className="screen-view">
          <div className="hor-flex">
            <div className="flex-left-align">
              {/* <div
                              dangerouslySetInnerHTML={{
                                __html: description,
                              }}
                            /> */}
              {tabs && (
                <div className="tab-container">
                  {Object.keys(tabs).map((tab, index) => {
                    if (tab != "Frequently Asked Questions") {
                      return (
                        <div
                          key={index}
                          className={`tab-name ${
                            activeTab === tab ? "active" : ""
                          }`}
                          onClick={() => setActiveTab(tab)}
                        >
                          {tab}
                        </div>
                      );
                    }
                  })}
                </div>
              )}
              <div className="frame-20">
                <div className="frame-21">
                  <>
                    <div className="div-head">
                      {activeTab && tabs[activeTab] && (
                        <div className="tab-content">
                          <h3>{activeTab}</h3>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: tabs[activeTab],
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </>
                </div>
              </div>

              {tabs["Frequently Asked Questions"] ? (
                <>
                  <div className="frame-22" onClick={toggleCollapse}>
                    <div className="text-wrapper-141">
                      Frequently Asked Questions
                    </div>
                    <img
                      className={`arrow-icon ${
                        !isCollapsed ? "collapsed" : ""
                      }`}
                      alt="Arrow"
                      src="/down-arrow.svg"
                    />
                  </div>
                  {!isCollapsed && (
                    <>
                      <div className="div-head frame-22">
                        {
                          <div
                            className="description"
                            dangerouslySetInnerHTML={{
                              __html: tabs["Frequently Asked Questions"],
                            }}
                          />
                        }
                      </div>
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="right-align">
              {alternateProducts.length > 0 && (
                <div className="screen-view-item">
                  <div className="frame-4">
                    <div className="text-wrapper-3">
                      Products with this Salt “{salt_name}”
                    </div>
                    <div className="overlap-3">
                      <div className="product-list">
                        {alternateProducts.map((altProduct, index) => (
                          <div className="product-item" key={index}>
                            <img
                              className="product-image"
                              alt="Alternate Product"
                              src={
                                "https://medingen.s3.us-east-1.amazonaws.com/products/" +
                                altProduct.imageUrl
                              }
                            />
                            <div className="product-info">
                              <div className="alternate-product-name">
                                {altProduct.name}
                              </div>
                              <div className="product-manufacturer">
                                {altProduct.manufacturer}
                              </div>
                              <div className="price-info">
                                {/* <div className="original-price">
                            Rs. {altProduct.originalPrice}
                          </div> */}
                                <div className="discounted-price">
                                  Rs. {altProduct.discountedPrice}
                                </div>
                                {altProduct.discount > 0 && (
                                  <div className="price-tag">
                                    <div className="price-discount">
                                      <div className="discount">
                                        {altProduct.discount}% less Price
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div
                              className="add-to-cart"
                              onClick={() => handleView(altProduct)}
                            >
                              <div className="add-button">View</div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="landing-page">
          <Footer />
        </div>
      </div>
      <Navigation />
    </>
  );
};
