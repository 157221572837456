import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {
  listAddresses,
  addAddress,
  updateAddress,
  deleteAddress,
  selectAddress,
} from "../../api/Api";
import "./style.css";
import Cookies from "js-cookie";
import Header from "../Dashboard/Header";

// API Endpoint for geoapify
const GEOAPIFY_API_KEY = "b7ac31a270df4a0c8a896740e2e96493";

export const SavedAddress = ({ chooseAddress }) => {
  // Accept chooseAddress as a prop
  const [addresses, setAddresses] = useState([]);
  const [currentLocation, setCurrentLocation] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchAddresses();
  }, []);

  useEffect(() => {
    if (searchQuery) {
      fetchSuggestions(searchQuery);
    } else {
      setSearchResults([]);
    }
  }, [searchQuery]);

  const fetchAddresses = async () => {
    try {
      const fetchedAddresses = await listAddresses(navigate);
      setAddresses(fetchedAddresses || []);
    } catch (error) {
      console.error("Error fetching addresses", error);
      Swal.fire({
        title: "Error!",
        text: "Failed to load addresses.",
        icon: "error",
        confirmButtonText: "Try again",
      });
    }
  };

  const handleVoiceClick = () => {
    Swal.fire({
      icon: "info",
      text: "Voice search feature currently not available.",
      showConfirmButton: true,
    });
  };

  const fetchSuggestions = async (query) => {
    try {
      const response = await fetch(
        `https://api.geoapify.com/v1/geocode/autocomplete?text=${query}&apiKey=${GEOAPIFY_API_KEY}`
      );
      const data = await response.json();
      setSearchResults(data.features);
    } catch (error) {
      console.error("Error fetching suggestions", error);
    }
  };

  const getCurrentLocation = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          const locationResponse = await fetch(
            `https://geocode.maps.co/reverse?lat=${latitude}&lon=${longitude}&api_key=66d4782bb3d09960167594uts506c6d`
          );
          const locationData = await locationResponse.json();
          const city = locationData.address.city || "Unknown City";
          const area = locationData.address.road || "Unknown Area";
          setCurrentLocation({
            pincode: locationData.address.postcode,
            address1: `${area}, ${city}`,
            state: locationData.address.state,
          });
        },
        (error) => {
          console.error("Error obtaining location", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const handleAddAddress = () => {
    navigate("/addressnew", { state: { ...currentLocation } });
  };

  const handleEditAddress = (address) => {
    navigate("/addressnew", { state: { ...address, editing: true } });
  };

  const handleDeleteAddress = async (id) => {
    // Confirm before deleting
    const confirmDelete = await Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this address?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    });

    if (!confirmDelete.isConfirmed) {
      return;
    }
    
    try {
      await deleteAddress(id, navigate);
      fetchAddresses(); // Refresh the list after deletion
      Swal.fire({
        title: "Success!",
        text: "Address deleted successfully.",
        icon: "success",
        confirmButtonText: "OK",
      });
    } catch (error) {
      console.error("Error deleting address", error);
      Swal.fire({
        title: "Error!",
        text: "Failed to delete address.",
        icon: "error",
        confirmButtonText: "Try again",
      });
    }
  };

  const handleSelectAddress = async (id) => {
    try {
      await selectAddress(id, navigate);
      Cookies.set("selectedAddress", id);
      Swal.fire({
        title: "Success!",
        text: "Default address updated successfully.",
        icon: "success",
        confirmButtonText: "OK",
      }).then(() => navigate("/dashboard"));
    } catch (error) {
      console.error("Error selecting address", error);
      Swal.fire({
        title: "Error!",
        text: "Failed to select address.",
        icon: "error",
        confirmButtonText: "Try again",
      });
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleChooseAddress = (addressId) => {
    if (chooseAddress && typeof chooseAddress === "function") {
      chooseAddress(addressId);
    }
  };

  return (
    <>
      <Header title={chooseAddress ? "Select Address" : "Saved Address"} />

      <div className="address-saved">
        <div className="frame-2">
          <div className="group" />
          <div className="frame-wrapper">
            <div className="frame-3">
              <img
                className="img-2"
                alt="Search"
                src="/search.svg"
                style={{ height: "20px" }}
              />
              <div className="frame-4">
                <input
                  className="input-text"
                  type="text"
                  placeholder="Search for area, street name"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
                {searchResults.length > 0 && (
                  <ul className="suggestions-list">
                    {searchResults.map((result) => (
                      <li
                        key={result.properties.place_id}
                        onClick={() =>
                          navigate("/addressnew", {
                            state: {
                              state: result.properties.state,
                              pincode: result.properties.postcode,
                              address1:
                                result.properties.address_line1 +
                                ", " +
                                result.properties.suburb,
                            },
                          })
                        }
                      >
                        {result.properties.formatted}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <img
                className="img"
                alt="Fluent mic"
                src="/fluent-mic-20-regular.svg"
                onClick={handleVoiceClick}
              />
            </div>
          </div>
        </div>
        <div className="frame-5">
          <div className="frame-6">
            <div className="frame-7" onClick={getCurrentLocation}>
              <img className="img-2" alt="Location" src="/location-bold.svg" />
              <div className="text-wrapper-2 current">Use Current Location</div>
            </div>
            <p className="text-wrapper-3">
              {!currentLocation.address1 && "No location detected."}
              {currentLocation.address1 &&
                currentLocation.address1 + " " + currentLocation.pincode}
            </p>
            <div className="div-wrapper">
              <div className="frame-8" onClick={handleAddAddress}>
                <div className="text-wrapper-4">+</div>
                <div className="text-wrapper-5">Add Address</div>
              </div>
            </div>
          </div>
          <div className="frame-9">
            <div className="frame-10">
              <img className="vector" alt="Vector" src="/vector-212-1.svg" />
              <div className="saved-address">Saved&nbsp;&nbsp;Address</div>
              <img className="vector" alt="Vector" src="/vector-212-1.svg" />
            </div>
            <div className="frame-11">
              {addresses.length === 0 ? (
                <div className="info-box">No saved addresses</div>
              ) : (
                addresses.map((address) => (
                  <div key={address.id} className="frame-12">
                    <div className="frame-13">
                      <img
                        className="group-2"
                        alt="Group"
                        src={
                          address.type == "home"
                            ? "home_icon.svg"
                            : "group-3016848.png"
                        }
                      />
                      <div className="text-wrapper-2">{address.type}</div>
                    </div>
                    <p className="text-wrapper-3">{address.name}</p>
                    <p className="text-wrapper-3">
                      {address.address1}, {address.pincode}, {address.state}
                    </p>
                    <p className="text-wrapper-3">
                      {address.phone_number}
                    </p>
                    <div className="text-wrapper-6">
                      {chooseAddress ? (
                        <span onClick={() => handleChooseAddress(address.id)}>
                          Select Address
                        </span>
                      ) : (
                        <>
                          <span onClick={() => handleEditAddress(address)}>
                            Edit Address
                          </span>
                          {!address.default && (
                            <span
                              onClick={() => handleSelectAddress(address.id)}
                            >
                              Default Address
                            </span>
                          )}
                          <span onClick={() => handleDeleteAddress(address.id)}>
                            Delete Address
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
