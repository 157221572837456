import React, { useState, useEffect } from "react";
import "./style.css";
import Header from "../Dashboard/Header";
import Navigation from "../Dashboard/Navigation";
import {
  getCartData,
  updateCartData,
  placeOrder,
  updateChoosePrescription,
  updateDeliveryAddress,
  getCartDataForID,
  getTrackingDetails,
  cancelOrder,
} from "../../api/Api";
import Swal from "sweetalert2";
import { SavedAddress } from "../SavedAddress/SavedAddress";
import { UploadPrescription } from "../UploadPrescription/UploadPrescription";
import { useLocation, useNavigate } from "react-router-dom";
import { Footer } from "../LandingPage/LandingPage";

function formatPrescriptionDate(dateString) {
  const date = new Date(dateString);
  const istOffset = 5 * 60 + 30; // IST is UTC+5:30
  const localDate = new Date(date.getTime() + istOffset * 60 * 1000);
  const options = { day: "2-digit", month: "short", year: "numeric" };
  return localDate.toLocaleDateString("en-IN", options);
}

export const OrderProgress = () => {
  const [cartStatus, setCartStatus] = useState("active");
  const [cartId, setCartId] = useState("");
  const [cartItems, setCartItems] = useState([]);
  const [prescriptionDetails, setPrescriptionDetails] = useState({});
  const [trackingDetails, setTrackingDetails] = useState([]);
  const [orderSummary, setOrderSummary] = useState({
    itemsCount: 0,
    totalMRP: "Rs. 0",
    totalPercentageSaved: "0%",
    totalSavings: "Rs. 0",
    migCoins: "0 Coins",
    totalAmount: "Rs. 0",
  });
  const [deliveryAddress, setDeliveryAddress] = useState({
    addressLine1: "",
    addressLine2: "",
  });
  const [progressSteps, setProgressSteps] = useState([
    { label: "Place Order", status: "pending" },
    { label: "Confirmation", status: "pending" },
    { label: "Payment", status: "pending" },
    { label: "Delivery", status: "pending" },
  ]);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [updatedQuantities, setUpdatedQuantities] = useState({});
  const [showUpdateButton, setShowUpdateButton] = useState(false);
  const [showAddressComponent, setShowAddressComponent] = useState(false); // State to control address component visibility
  const [showPrescriptionComponent, setShowPrescriptionComponent] =
    useState(false); // State to control prescription component visibility
  const location = useLocation();
  const [cart_id_external, setCartIdExternal] = useState(
    location.state && (location.state.cart_id ? location.state.cart_id : null)
  );

  const navigate = useNavigate();

  const load_data = async () => {
    Swal.showLoading();
    const passedState = location.state;
    let response;
    if (passedState && passedState.cart_id) {
      response = await getCartDataForID(passedState.cart_id);
    } else {
      response = await getCartData();
    }
    if (response.status != 200) {
      Swal.fire({
        icon: "warning",
        title: "Cart is empty",
        text: "Please add items to your cart before proceeding.",
      });
      navigate("/dashboard");
    }

    const data = await response.data;

    Swal.close();
    setCartItems(data.cart);
    setDeliveryAddress(data.deliveryAddress);
    setOrderSummary(data.orderSummary);
    setPrescriptionDetails(data.prescriptionDetails || {});
    setTrackingDetails(data.courier_tracking.trackDetails);
    return data;
  };

  const handlePay = () => {
    navigate("/order-payment", {
      state: {
        total_amount: parseFloat(orderSummary.totalAmount),
        mig_coins: orderSummary.migCoins,
        cart_id: cartId,
      },
    });
  };
  const fetchData = async () => {
    try {
      const data = await load_data();

      setCartStatus(data.cartStatus);
      setCartId(data.cart_id);
      if (data.cartStatus === "active") {
        setProgressSteps([
          { label: "Place Order", status: "pending" },
          { label: "Confirmation", status: "pending" },
          { label: "Payment", status: "pending" },
          { label: "Delivery", status: "pending" },
        ]);
        setConfirmationMessage(
          "Place your order, and our experts will confirm. We'll notify you when it's ready for payment."
        );
      } else if (data.cartStatus === "pending_confirm") {
        setProgressSteps([
          { label: "Place Order", status: "completed" },
          { label: "Confirmation", status: "pending" },
          { label: "Payment", status: "pending" },
          { label: "Delivery", status: "pending" },
        ]);
        setConfirmationMessage(
          "Our Experts are confirming your order. Please wait for the confirmation. Feel free to update the order any time by adding/removing items from this cart."
        );
        // Swal.fire({
        //   icon: "info",
        //   title: "Order Placed",
        //   showConfirmButton: false,
        //   timer: 1500,
        //   text: "Your order has been placed. Our experts will confirm your order shortly.",
        // });
      } else if (data.cartStatus === "confirm") {
        setProgressSteps([
          { label: "Place Order", status: "completed" },
          { label: "Confirmation", status: "current" },
          { label: "Payment", status: "pending" },
          { label: "Delivery", status: "pending" },
        ]);
        setConfirmationMessage(
          "Our Experts confirmation are done, please review your order and make payment."
        );
        Swal.fire({
          icon: "success",
          title: "Order Confirmed",
          showConfirmButton: false,
          timer: 1500,
          text: "Your order has been confirmed. Please review your order and proceed to payment.",
        });
      } else if (data.cartStatus === "payment") {
        setProgressSteps([
          { label: "Place Order", status: "completed" },
          { label: "Confirmation", status: "completed" },
          { label: "Payment", status: "current" },
          { label: "Delivery", status: "pending" },
        ]);
        setConfirmationMessage(
          "Your payment is confirmed. Your order is on the way. We'll notify you when it's out for delivery."
        );
      } else if (data.cartStatus === "delivered") {
        setProgressSteps([
          { label: "Place Order", status: "completed" },
          { label: "Confirmation", status: "completed" },
          { label: "Payment", status: "completed" },
          { label: "Delivery", status: "current" },
        ]);
        setConfirmationMessage(
          "Your order has been delivered. Thank you for shopping with us."
        );
      } else if (data.cartStatus === "cancelled") {
        setProgressSteps([
          { label: "Place Order", status: "completed" },
          { label: "Confirmation", status: "completed" },
          { label: "Payment", status: "current" },
          { label: "Delivery", status: "pending" },
        ]);
        setConfirmationMessage(
          "Your order is cancelled."
        );
      }
    } catch (error) {
      console.error("Failed to fetch cart data", error);
      Swal.fire({
        icon: "warning",
        title: "Cart is empty",
        text: "Please add items to your cart before proceeding.",
      }).then(() => {
        navigate("/dashboard");
      });
    }
  };

  useEffect(() => {
    fetchData();
    setCartIdExternal(
      location.state && (location.state.cart_id ? location.state.cart_id : null)
    );
  }, [location.state]);

  const handleQuantityChange = (id, change) => {
    setCartItems((prevItems) => {
      const newItems = prevItems.map((item) => {
        if (item.id === id) {
          const newQuantity = Math.max(item.quantity + change, 0);
          if (newQuantity !== item.quantity) {
            setShowUpdateButton(true);
            return { ...item, quantity: newQuantity };
          }
        }
        return item;
      });
      return newItems;
    });
  };

  const handleUpdateCart = async () => {
    const productIdsQty =
      cartItems
        .filter((item) => item.quantity > 0)
        .map((item) => `${item.id}:${item.quantity}`)
        .join(";") + ";";

    try {
      const data = await updateCartData(productIdsQty, cartId);
      Swal.fire({
        icon: "success",
        title: "Cart updated successfully",
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        fetchData();
      });
      setShowUpdateButton(false);
    } catch (error) {
      console.error("Failed to update cart", error);
    }
  };

  const handlePrescriptionChangeClick = () => {
    setShowPrescriptionComponent(true); // Show the UploadPrescription component
  };

  const handleAddressChangeClick = () => {
    setShowAddressComponent(true); // Show the SavedAddress component
  };

  const handlePrescriptionUpdate = async (prescription_id) => {
    try {
      await updateChoosePrescription(prescription_id, cartId); // Ensure this is the correct API call
      const data = await load_data(); // Reload data to reflect prescription changes
      setPrescriptionDetails(data.prescriptionDetails);
      setShowPrescriptionComponent(false); // Hide the UploadPrescription component
    } catch (error) {
      console.error("Failed to update prescription", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to update prescription.",
      });
    }
  };

  const handleAddressUpdate = async (addressId) => {
    try {
      await updateDeliveryAddress(addressId, cartId); // Call API to update delivery address
      const data = await load_data(); // Reload data to reflect address changes
      setDeliveryAddress(data.deliveryAddress);
      setShowAddressComponent(false); // Hide the SavedAddress component
    } catch (error) {
      console.error("Failed to update delivery address", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to update delivery address.",
      });
    }
  };

  const handleCancel = async () => {
    try {
      const response = await cancelOrder(cartId);
      if (response) {
        Swal.fire({
          icon: "success",
          title: "Order Cancelled",
          text: "Your order has been cancelled successfully.",
          showConfirmButton: true,
        }).then(() => {
          navigate("/dashboard");
        });
      }
    }
    catch (error) {
      console.error("Failed to cancel order", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an issue cancelling your order. Please try again.",
      });
    }
  };

  const handlePlaceOrder = async () => {
    // Check if delivery address is not empty
    if (deliveryAddress === null) {
      Swal.fire({
        icon: "warning",
        title: "Delivery Address Missing",
        text: "Please provide a delivery address before placing your order.",
      });
      return;
    }

    // Check if a prescription is attached for items that require it
    const requiresPrescription = cartItems.some(
      (item) => item.prescriptionRequired === "Yes"
    );

    if (requiresPrescription && Object.keys(prescriptionDetails).length === 0) {
      Swal.fire({
        icon: "warning",
        title: "Prescription Missing",
        text: "At least one of your items requires a prescription. Please attach a prescription to proceed.",
      });
      return;
    }

    try {
      // If both checks are passed, update the cart status to pending confirm
      const response = await placeOrder(cartId, orderSummary.totalAmount);
      if (response) {
        Swal.fire({
          icon: "success",
          title: "Order Placed Successfully",
          text: "Your order has been placed successfully. Our experts will confirm your order shortly.",
          showConfirmButton: true,
        }).then(() => {
          // Update the cart status in the component
          setCartStatus("pending_confirm");

          // Update the progress steps for "Pending Confirmation" status
          setProgressSteps((prevSteps) =>
            prevSteps.map((step, index) =>
              index === 1 ? { ...step, status: "current" } : step
            )
          );

          navigate("/order-progress");
        });
      }

      // Change the button label to "Update Order"
    } catch (error) {
      console.error("Failed to place order", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an issue placing your order. Please try again.",
      });
    }
  };

  const Tracking = ({ trackDetails }) => {
    // Deduplicate actions by strAction while maintaining order
    const uniqueActions = trackDetails.reduce((acc, curr) => {
      if (!acc.find((item) => item.strAction === curr.strAction)) {
        acc.push(curr);
      }
      return acc;
    }, []);

    // Format date and time to a readable format
    const formatDateTime = (date, time) => {
      const formattedDate = `${date.slice(0, 2)}/${date.slice(
        2,
        4
      )}/${date.slice(4)}`;
      const formattedTime = `${time.slice(0, 2)}:${time.slice(2)}`;
      return `${formattedDate} ${formattedTime}`;
    };

    return (
      <div className="tracking-container">
        {uniqueActions.length > 0 ? (
          <div className="vertical-line">
            {uniqueActions.reverse().map((item, index) => (
              <div key={index} className="tracking-step">
                <div className="circle"></div>
                <div className="tracking-info">
                  <p className="action">{item.strAction}</p>
                  <p className="date-time">
                    {formatDateTime(item.strActionDate, item.strActionTime)}
                  </p>
                  {item.strOrigin && <p className="origin">{item.strOrigin}</p>}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p className="no-tracking-info">No tracking info available</p>
        )}
      </div>
    );
  
  };

  return (
    <>
      {showPrescriptionComponent ? (
        <UploadPrescription choosePrescription={handlePrescriptionUpdate} /> // Pass handler to UploadPrescription
      ) : showAddressComponent ? (
        <SavedAddress chooseAddress={handleAddressUpdate} /> // Pass handler to SavedAddress
      ) : (
        <>
          {cart_id_external && (
            <Header title={"Order ID: " + cart_id_external} />
          )}
          {!cart_id_external && <Header title="Active cart" />}

          <div className="order-progress">
          <div className="orders-section">
            <div className="order-tracker">
              <div className="progress-bar">
                <div alt="Progress" className="progress-line" />
                {progressSteps.map((step, index) => (
                  <div key={index} className={`step ${step.status}`} />
                ))}
              </div>
              <div className="steps-label">
                {progressSteps.map((step, index) => (
                  <span key={index} className={`step-label ${step.status}`}>
                    {step.label}
                  </span>
                ))}
              </div>
            </div>

            <div className="confirmation-message">
              <p>{confirmationMessage}</p>
              <br />
              <br />
              {orderSummary.itemsCount > 0 &&
                progressSteps[1].status === "current" && (
                  <div className="note">
                    <img
                      src="/material-symbols-light-info.svg"
                      alt="Info"
                      className="info-icon"
                    />
                    <p>
                      * If you add more medicines, you will need to go through
                      our experts' confirmation again.
                    </p>
                  </div>
                )}
            </div>

            <div className="cart">
              <div className="cart-header">
                <div className="cart-title">Your Cart</div>
              </div>
              <div className="cart-items">
                {cartItems.map((item) => (
                  <CartItem
                    image={item.image}
                    key={item.id}
                    name={item.name}
                    manufacturer={item.manufacturer}
                    originalPrice={item.originalPrice}
                    discountedPrice={item.discountedPrice}
                    discountPercentage={item.discountPercentage}
                    prescription={item.prescriptionRequired == "Yes"}
                    quantity={item.quantity}
                    cart_id_external={cart_id_external}
                    onQuantityChange={(change) =>
                      handleQuantityChange(item.id, change)
                    }
                  />
                ))}
              </div>
            </div>

            {showUpdateButton && (
              <div className="checkout-button" onClick={handleUpdateCart}>
                <button className="pay-button">
                  <span>Update cart</span>
                </button>
              </div>
            )}

            <div className={showUpdateButton ? "update-cart" : ""}>
              <div className="order-summary">
                <div className="summary-details">
                  <div className="summary-title">Order Summary</div>
                  <SummaryRow
                    label="No. of Items"
                    value={orderSummary.itemsCount}
                  />
                  <SummaryRow label="Total MRP" value={orderSummary.totalMRP} />
                  <SummaryRow
                    label="Selling price"
                    value={orderSummary.total_selling_price}
                  />
                  {cartStatus === "confirm" && (
                    <SummaryRow
                      label="Shipping charge"
                      value={orderSummary.total_shipping_charge}
                    />
                  )}

                  <SummaryRow
                    label="Total Savings in this Order"
                    value={
                      orderSummary.totalSavings +
                      " (" +
                      orderSummary.totalPercentageSaved +
                      ")"
                    }
                  />
                  <SummaryRow
                    label="MIG Coins available"
                    value={orderSummary.migCoins}
                  />
                </div>
                <div className="total-amount">
                  <p>Total Amount</p>
                  <div className="amount">Rs. {orderSummary.totalAmount}</div>
                </div>

                <div className="delivery-details">
                  <div className="delivery-title">Prescription attached</div>
                  <div className="prescrip-container">
                    {Object.keys(prescriptionDetails).length > 0 && (
                      <div className="prescrip-image">
                        <img
                          src={
                            "https://medingen.s3.us-east-1.amazonaws.com/prescription/" +
                            prescriptionDetails.prescription_image_url
                          }
                          alt="Prescription"
                          className="prescrip-img"
                        />
                      </div>
                    )}

                    <div className="delivery-address">
                      {Object.keys(prescriptionDetails).length > 0 && (
                        <>
                          <div className="address">
                            Name: {prescriptionDetails.prescription_name}
                          </div>
                          <div className="address-details">
                            {prescriptionDetails.prescription_date &&
                              "Upload Date: " +
                                formatPrescriptionDate(
                                  prescriptionDetails.prescription_date
                                )}
                          </div>
                          <div className="address-details">
                            {prescriptionDetails.last_used_date &&
                              "Last used on " +
                                prescriptionDetails.last_used_date}
                          </div>
                          <br />
                        </>
                      )}
                      {!cart_id_external && (
                        <div
                          className="change-address"
                          onClick={handlePrescriptionChangeClick}
                        >
                          Change
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="delivery-details">
                  <div className="delivery-title">Delivering to</div>
                  <div className="prescrip-container">
                    <div className="prescrip-image">
                      {deliveryAddress && (
                        <img
                          className="group-2"
                          alt="Group"
                          style={{ width: "25px" }}
                          src={
                            deliveryAddress.type == "home"
                              ? "home_icon.svg"
                              : "group-3016848.png"
                          }
                        />
                      )}
                    </div>
                    <div className="delivery-address">
                      {deliveryAddress && (
                        <>
                          <div className="address">{deliveryAddress.name}</div>
                          <div className="address-details">
                            {deliveryAddress.addressLine1},{" "}
                            {deliveryAddress.addressLine2},{" "}
                            {deliveryAddress.state}, {deliveryAddress.pincode}
                          </div>
                          <br />
                        </>
                      )}
                    </div>
                  </div>
                  {!cart_id_external && (
                    <div
                      className="change-address"
                      onClick={handleAddressChangeClick}
                    >
                      Change
                    </div>
                  )}
                </div>
                
                {(cartStatus === "payment" || cartStatus === "delivered"  || cartStatus === "cancelled") && (
                <div className="delivery-details">
                  <div className="delivery-title">Order tracking</div>
                  <Tracking trackDetails={trackingDetails} />
                </div>
              )}
              </div>

              {cartStatus === "active" ? (
                <div className="checkout-button">
                  <button className="pay-button" onClick={handlePlaceOrder}>
                    <span>Place order</span>
                    <img
                      src="/frame-3016860.svg"
                      className="arrow"
                      alt="Arrow"
                    />
                  </button>
                </div>
              ) : cartStatus === "pending_confirm" ? (
                <div className="checkout-button">
                  <button className="pay-button" onClick={handlePlaceOrder}>
                    <span>Update Order</span>
                    <img
                      src="/frame-3016860.svg"
                      className="arrow"
                      alt="Arrow"
                    />
                  </button>
                </div>
              ) : null}

              {cartStatus === "confirm" && (
                <div className="checkout-button">
                  <button className="pay-button" onClick={handlePay}>
                    <span>Proceed to Pay</span>
                    <img
                      src="/frame-3016860.svg"
                      className="arrow"
                      alt="Arrow"
                    />
                  </button>
                </div>
              )}
              {cartStatus === "payment" && (
                <div className="checkout-button">
                  <button className="pay-button" onClick={handleCancel}>
                    <span>Cancel order</span>
                    <img
                      src="/frame-3016860.svg"
                      className="arrow"
                      alt="Arrow"
                    />
                  </button>
                </div>
              )}
            </div>
            <div className="margin-bottom"></div>
                      </div>

               <div className="landing-page">
                      <Footer />
                    </div>
          </div>
          <Navigation />
        </>
      )}
    </>
  );
};

const CartItem = ({
  name,
  image,
  manufacturer,
  originalPrice,
  discountedPrice,
  discountPercentage,
  quantity,
  onQuantityChange,
  prescription,
  cart_id_external,
}) => (
  <div className="cart-item">
    <img
      src={"https://medingen.s3.us-east-1.amazonaws.com/products/" + image}
      alt={name}
      className="cart-image"
    />
    <div className="cart-info">
      <div className="cart-name">{name}</div>
      <div className="cart-manufacturer">{manufacturer}</div>
      <div className="cart-pricing">
        <span className="original-price">{originalPrice}</span>
        <span className="discounted-price">{discountedPrice}</span>
        <span className="discount-percentage">{discountPercentage}</span>
        {prescription && (
          <span className="discount-percentage">
            <img src="/rx.png" alt="Rx" className="rx" />
          </span>
        )}
      </div>
    </div>
    {cart_id_external ? (
      <div className="quantity-control">
        Quantity: <span>{quantity}</span>
      </div>
    ) : (
      <div className="quantity-control">
        <button
          disabled={cart_id_external}
          onClick={() => onQuantityChange(-1)}
        >
          -
        </button>
        <span>{quantity}</span>
        <button disabled={cart_id_external} onClick={() => onQuantityChange(1)}>
          +
        </button>
      </div>
    )}
  </div>
);

const SummaryRow = ({ label, value }) => (
  <div className="summary-row">
    <span>{label}</span>
    <span>{value}</span>
  </div>
);
