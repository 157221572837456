import React, { useEffect, useState, useRef } from "react";
import "./style.css";
import Header from "../Dashboard/Header";
import Navigation from "../Dashboard/Navigation";
import { Footer } from "../LandingPage/LandingPage";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { getAllBlogCategories, getAllBlogs } from "../../api/Api";
import Swal from "sweetalert2";

export const PopularBlogs = ({ slidesPerView }) => {
  const [popularBlogs, setPopularBlogs] = useState([]);
  const popularSwiperRef = useRef(null);

  useEffect(() => {
    const fetchPopularBlogs = async () => {
      try {
        const blogs = await getAllBlogs(true);
        setPopularBlogs([
          ...blogs,
          ...blogs,
          ...blogs,
          ...blogs,
          ...blogs,
          ...blogs,
          ...blogs,
        ]);
      } catch (error) {
        console.error("Error fetching popular blogs:", error);
      }
    };

    fetchPopularBlogs();
  }, []);

  const handleRedirect = (url) => {
    window.open(url, "_blank");
  };

  return (
    <>
      <div className="dashboard-item">
        <p className="text-wrapper-head-2">Popular Blogs</p>
        <div className="pagination-controls">
          <button
            onClick={() => popularSwiperRef.current?.slidePrev()}
            disabled={!popularSwiperRef.current}
          >
            <img
              src="/vector-arrow.svg"
              alt="Previous"
              style={{ transform: "scaleX(-1)" }}
            />
          </button>
          <button
            onClick={() => popularSwiperRef.current?.slideNext()}
            disabled={!popularSwiperRef.current}
          >
            <img src="/vector-arrow.svg" alt="Next" />
          </button>
        </div>
      </div>
      <div className="dashboard-item">
        <Swiper
          onSwiper={(swiper) => (popularSwiperRef.current = swiper)}
          spaceBetween={20}
          slidesPerView={slidesPerView}
        >
          {popularBlogs.map((blog, index) => (
            <SwiperSlide key={index}>
              <div className="popular-blog-item">
                <div
                  onClick={() => handleRedirect("/blog/" + blog.blog_url)}
                >
                  <img
                    className="popular-blog-image"
                    alt="Image"
                    src={`https://medingen.s3.us-east-1.amazonaws.com/blogs/images/${blog.blog_image_url}`}
                  />
                  <div className="popular-blog-text">{blog.blog_name}</div>
                  <div className="popular-blog-date">
                    {new Date(blog.blog_created_date).toLocaleDateString()}
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

export const RecommendedBlogs = ({ slidesPerView }) => {
  const [recommendedBlogs, setRecommendedBlogs] = useState([]);
  const recommendedSwiperRef = useRef(null);

  useEffect(() => {
    const fetchRecommendedBlogs = async () => {
      try {
        const blogs = await getAllBlogs();
        setRecommendedBlogs([
          ...blogs,
          ...blogs,
          ...blogs,
          ...blogs,
          ...blogs,
          ...blogs,
          ...blogs,
          ...blogs,
          ...blogs,
          ...blogs,
          ...blogs,
        ]);
      } catch (error) {
        console.error("Error fetching recommended blogs:", error);
      }
    };

    fetchRecommendedBlogs();
  }, []);

  const handleRedirect = (url) => {
    window.open(url, "_blank");
  };

  return (
    <>
      <div className="dashboard-item">
        <p className="text-wrapper-head-2">Recommended Blogs</p>
        <div className="pagination-controls">
          <button
            onClick={() => recommendedSwiperRef.current?.slidePrev()}
            disabled={!recommendedSwiperRef.current}
          >
            <img
              src="/vector-arrow.svg"
              alt="Previous"
              style={{ transform: "scaleX(-1)" }}
            />
          </button>
          <button
            onClick={() => recommendedSwiperRef.current?.slideNext()}
            disabled={!recommendedSwiperRef.current}
          >
            <img src="/vector-arrow.svg" alt="Next" />
          </button>
        </div>
      </div>
      <div className="dashboard-item">
        <Swiper
          onSwiper={(swiper) => (recommendedSwiperRef.current = swiper)}
          spaceBetween={20}
          slidesPerView={slidesPerView}
        >
          {recommendedBlogs.map((blog, index) => (
            <SwiperSlide key={index}>
              <div className="popular-blog-item">
                <div
                  onClick={() => handleRedirect("/blog/" + blog.blog_url)}
                >
                  <img
                    className="popular-blog-image"
                    alt="Image"
                    src={`https://medingen.s3.us-east-1.amazonaws.com/blogs/images/${blog.blog_image_url}`}
                  />
                  <div className="popular-blog-text">{blog.blog_name}</div>
                  <div className="popular-blog-date">
                    {new Date(blog.blog_created_date).toLocaleDateString()}
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

export const Blogs = () => {
  const [blogCategories, setBlogCategories] = useState([]);
  const [slidesPerView, setSlidesPerView] = useState("auto");

  const updateSlidesPerView = () => {
    if (window.innerWidth < 700) {
      setSlidesPerView(1);
    } else {
      setSlidesPerView("auto");
    }
  };

  useEffect(() => {
    updateSlidesPerView();
    window.addEventListener("resize", updateSlidesPerView);

    return () => {
      window.removeEventListener("resize", updateSlidesPerView);
    };
  }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        Swal.showLoading();
        const categories = await getAllBlogCategories();
        setBlogCategories(categories);
        Swal.close();
      } catch (error) {
        console.error("Error fetching categories:", error);
        Swal.close();
      }
    };

    fetchCategories();
  }, []);

  return (
    <>
      <Header title={"Blogs"} />
      <div className="blogs">
        <div className="blog-container">
          <div className="dashboard-item">
            <p className="text-wrapper-head">Explore categories</p>
          </div>
          <div className="dashboard-item-cat">
            <div className="category-grid">
              {blogCategories.map((category, index) => (
                <div key={index} className="category-item">
                  <img
                    className="category-image"
                    alt="Image"
                    src={`https://medingen.s3.us-east-1.amazonaws.com/blogs/images/${category.category_image_url}`}
                  />
                  <div className="text-wrapper-10">
                    {category.category_display_name}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <PopularBlogs slidesPerView={slidesPerView} />
          <RecommendedBlogs slidesPerView={slidesPerView} />
        </div>

        <div className="margin-72"></div>

        <div className="landing-page">
          <Footer />
        </div>
      </div>
      <Navigation />
    </>
  );
};
